
<div class="login-screen">
  <div class="login-screen__image"></div>
<form
  [formGroup]="loginForm"
  (ngSubmit)="submit()"
  class="login-form">
  <fieldset>
    <tngh-form-section
      [info]="false"
      title="Login"
      i18n-title="login@@otpLoginTitle"
    >
      <tngh-form-grid>
        <tngh-text-input
          class="span-6"
          formControlName="emailAddress"
          autocapitalize="none"
          label="Email address"
          i18n-label="Label emailaddress@@emailaddress"
          [showFormErrors]="showFormErrors"></tngh-text-input>
        @if (passwordRequired) {
          <tngh-text-input
            class="span-6"
            autocapitalize="none"
            type="password"
            formControlName="password"
            label="Password"
            i18n-label="Label password@@password"
            [showFormErrors]="showFormErrors"></tngh-text-input>
        }
        @if (emailSent) {
          <tngh-pin-input
            formControlName="otp"
            [isCodeHidden]="false"
          ></tngh-pin-input>
        }
        <tngh-alert
          *ngIf="loginError"
          class="span-6"
          type="danger"
          title="Unable to login"
          i18n-title="login error alert email@@loginFormAlertOTP"
          alertText="Make sure your pass code is correct."
          i18n-alertText="login error alert text@@loginFormAlertTextOTP"></tngh-alert>
      </tngh-form-grid>
    </tngh-form-section>
  </fieldset>
  <div class="login-screen__cta">
    @if (emailSent) {
      <tngh-button
        i18n-label="resend OTP button@@resendOTPButton"
        label="Resend passcode"
        [fullwidth]="true"
        type="button"
        variant="outline"
        (clickEvent)="sendOtp()"
        class="login-form__btn"/>
      <tngh-button
        i18n-label="login button|The button that will actually login the user@@loginButton"
        label="Login"
        [fullwidth]="true"
        type="submit"
        class="login-form__btn"/>
    } @else {
      <tngh-button
        i18n-label="send OTP button@@sendOTPButton"
        label="Send passcode"
        [fullwidth]="true"
        type="button"
        variant="primary"
        (clickEvent)="sendOtp()"
        class="login-form__btn"/>
    }

    <br>
    @if (allowSignup) {
      <span i18n="sign up text@@signUpText">Don't have an account?</span>
      <tngh-button
        label="sign up!"
        variant="link"
        (clickEvent)="signupClick()"
        i18n-label="sign up button@@signUpButton"
      ></tngh-button>
    }
  </div>
</form>
</div>
