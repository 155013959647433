import {Injectable} from '@angular/core';
import {BehaviorSubject, distinctUntilChanged, filter, interval, map, Observable, Subscription, tap} from 'rxjs';
import { HttpBackend, HttpClient, HttpParams } from '@angular/common/http';
import {Token} from '../modal/token';
import {headers} from '../modal/consts';
import {Attributes} from '../modal/attributes';
import {Router} from '@angular/router';
import {SharedModuleConfig} from "../../../../config/shared-module-config";


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  http: HttpClient;

  private token = new BehaviorSubject<string | undefined>(undefined);
  private expiresIn = 0;
  private refreshToken = '';
  private refreshSubscription$?: Subscription;
  private body;
  private tokenUrl;
  private loggingIn = new BehaviorSubject<boolean>(false);

  // Note: we inject the HttpBackend here, and create a new HttpClient(httpBackend) ourselves.
  // Directly injecting the HttpClient would cause a cyclic dependency, because we cannot inject the HttpClient into an interceptor.
  // See also https://stackoverflow.com/questions/45213352/httpinterceptor-service-httpclient-cyclic-dependency#answer-52856093
  constructor(httpBackend: HttpBackend, private router: Router, private sharedModuleConfig:  SharedModuleConfig) {
    this.http = new HttpClient(httpBackend);
    this.tokenUrl = `${sharedModuleConfig.keycloakUrl}/realms/${sharedModuleConfig.realm}/protocol/openid-connect/token`;
    this.body = new HttpParams().set('client_id', this.sharedModuleConfig.clientId).set('client_secret', this.sharedModuleConfig.clientSecret);
    this.readTokenFromLocalStorage();
  }

  getToken(): Observable<string> {
    return this.token.pipe(filter(Boolean), distinctUntilChanged());
  }

  isLoggedIn(): Observable<boolean> {
    return this.loggingIn.pipe(
      filter((loggingIn) => !loggingIn),
      map(() => !!this.token.value)
    );
  }

  login(emailaddress: string, password: string): Observable<Token> {
    this.loggingIn.next(true);
    this.body = this.body.set('username', emailaddress);
    const tokenRequestBody = this.body.set('password', password).set('grant_type', 'password').toString();

    return this.tokenCall(tokenRequestBody).pipe(
      tap(this.processToken.bind(this))
    );
  }

  processToken(token: Token) {
    this.loggingIn.next(false);
    this.storeTokenInfo(token);
    this.refreshCall();
  }

  logout() {
    this.refreshSubscription$?.unsubscribe();
    this.token.next(undefined);
    localStorage.removeItem('token');
    this.router.navigate([this.sharedModuleConfig.otpConfig ? '/authentication/login-otp' : '/authentication/login']);
  }

  getAttributes(): Observable<Attributes> {
    return this.getToken().pipe(map((token) => JSON.parse(atob(token.split('.')[1]))));
  }

  getCompany(): Observable<number> {
    return this.getAttributes().pipe(
      map((attributes) => +attributes.companies?.split(',')),
      distinctUntilChanged()
    );
  }

  getUserId(): Observable<number> {
    return this.getAttributes().pipe(
      map((attributes) => +attributes.sub?.split(':')[2]),
      distinctUntilChanged()
    );
  }

  getUsername(): Observable<string> {
    return this.getAttributes().pipe(
      map((attributes) => attributes.name),
      distinctUntilChanged()
    );
  }

  getRoles(): Observable<string[]> {
    return this.getAttributes().pipe(map((attributes) => attributes.roles?.split(',') ?? attributes.role?.split(',')));
  }

  getName(): Observable<string> {
    return this.getAttributes().pipe(map((attributes) => attributes.name));
  }

  getEmailAddress(): Observable<string> {
    return this.getAttributes().pipe(
      map((attributes) => attributes.preferred_username),
      distinctUntilChanged()
    );
  }

  private storeTokenInfo(tokenResponse: Token) {
    this.expiresIn = tokenResponse.expires_in;
    this.refreshToken = tokenResponse.refresh_token;
    this.token.next(tokenResponse.access_token);
    this.storeTokenInLocalStorage(tokenResponse);
  }

  private storeTokenInLocalStorage(tokenResponse: Token) {
    localStorage.setItem(
      'token',
      JSON.stringify({
        token: tokenResponse,
        expiresAt: Date.now() + tokenResponse.expires_in * 1000
      })
    );
  }

  private readTokenFromLocalStorage() {
    const tokenAsString = localStorage.getItem('token');
    if (tokenAsString) {
      const storedToken = JSON.parse(tokenAsString);
      if (storedToken.expiresAt > Date.now()) {
        this.storeTokenInfo(storedToken.token);
        this.refresh();
        this.refreshCall();
      } else {
        localStorage.removeItem('token');
      }
    }
  }

  public refreshCall() {
    this.refreshSubscription$?.unsubscribe();
    this.refreshSubscription$ = interval(this.delayTime()).subscribe(() => {
      this.refresh();
    });
  }

  public refresh() {
    this.tokenCall(this.body.set('refresh_token', this.refreshToken).set('grant_type', 'refresh_token').toString()).subscribe({
      next: (token) => {
        this.storeTokenInfo(token);
      },
      error: () => this.logout()
    });
  }

  private tokenCall(body: string): Observable<Token> {
    return this.http.post<Token>(this.tokenUrl, body, { headers });
  }

  private delayTime(): number {
    return (this.expiresIn - 5) * 1000;
  }
}
