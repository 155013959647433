import {Component} from '@angular/core';
import {OtpService, OtpLogin} from "shared";
import {ButtonComponent, OtpFormComponent, SignupFormComponent} from "component-library";
import {NavigationService} from "../auth/navigation/navigation.service";
import {AuthenticationConfig} from "../../config/authentication-config";

@Component({
  selector: 'lib-otp',
  standalone: true,
  imports: [
    SignupFormComponent,
    OtpFormComponent,
    ButtonComponent
  ],
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.css'
})
export class OtpComponent {
  signUp: boolean;

  constructor(public config: AuthenticationConfig, private readonly otpService: OtpService, private navigationService: NavigationService) {
    this.signUp = !!config.otpConfig?.allowSignup;
  }

  doSignup(newUser: OtpLogin) {
    this.otpService.signup(newUser).subscribe(() => this.signUp = false);
  }

  doSendOtpRequest(otpRequest: Partial<OtpLogin>) {
    this.otpService.sendOtpRequest(otpRequest).subscribe();
  }

  doLoginRequest(loginUser: OtpLogin) {
    this.otpService.loginRequest(loginUser).subscribe(() => {
      this.navigationService.back();
    });
  }

  signIn() {
    this.signUp = false;
  }
}
