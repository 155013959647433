import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormControl, ReactiveFormsModule} from '@angular/forms';
import { SingleFormControlCvaComponent } from 'shared';
import { FormFieldComponent } from '../form-field/form-field.component';
import { SvgIconComponent } from '../../base';
import { ValidationErrorMessageComponent } from '../../base';

@Component({
  standalone: true,
  selector: 'tngh-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: [
    '../form-styles/form-control-styles.scss',
    './text-input.component.scss'
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormFieldComponent,
    SvgIconComponent,
    ValidationErrorMessageComponent
  ]
})
export class TextInputComponent
  extends SingleFormControlCvaComponent
  implements AfterViewInit
{
  @Input() type = 'text';
  @Input() formControlName = '';
  @Input() showFormErrors?: boolean;
  @Input() pattern = '';
  @Input() maxlength: string | null = null;
  @Input() autocapitalize = '';
  @Input() placeholder = '';
  @Input() autocomplete = false;
  @Input() autoFocus = false;
  @Input() max: string | number | undefined | null;
  @Output() enter = new EventEmitter<string>();
  @Output() focusOut = new EventEmitter<EventTarget | null>();

  @ViewChild('input') input?: ElementRef<HTMLInputElement>;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  replaceComma($event: any) {
    const replacedCommaValue = $event.target.value.replace(/,/g, '.');

    if ($event.target.value !== replacedCommaValue) {
      this.formControl.setValue(replacedCommaValue);
    }
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onEnter(event: any) {
    this.enter.emit(event.target.value);
  }

  onBlur($event: Event) {
    this.focusOut.emit($event.target);
  }

  override ngAfterViewInit(): void {
    if (this.autoFocus) {
      this.input?.nativeElement.focus();
    }
  }

  temp(formControl: FormControl): void {
    console.log('disable')
    formControl.disable();
  }
}
