import { Component } from '@angular/core';
import { AuthService, User } from 'shared';
import { NavigationService } from '../auth/navigation/navigation.service';
import {AuthenticationConfig} from "../../config/authentication-config";
import { LoginFormComponent, MdefLoginFormComponent } from 'component-library';

@Component({
  selector: 'tngh-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  imports: [LoginFormComponent, MdefLoginFormComponent]
})
export class LoginComponent {
  loginError = false;
  showFormErrors = false;
  theme: AuthenticationConfig['theme'];

  constructor(private authService: AuthService, private navigationService: NavigationService, private config: AuthenticationConfig) {
    this.theme = config.theme ?? 'tngh';
  }

  doLogin(user: User) {
    this.loginError = false;
    this.authService.login(user.emailAddress, user.password).subscribe({
      next: () => {
        this.navigationService.back();
      },
      error: () => (this.loginError = true)
    });
  }

  navigateToPasswordReset() {
    this.navigationService.passwordResetRequest();
  }
}
