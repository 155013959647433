import { Route } from '@angular/router';
import { RoutingNames } from 'shared';
import { LoginComponent } from './login/login.component';
import { ChangePasswordPageComponent } from './change-password-page/change-password-page.component';
import { RequestPasswordResetPageComponent } from './request-password-reset-page/request-password-reset-page.component';
import { SetPasswordPageComponent } from './set-password-page/set-password-page.component';
import { ResetPasswordPageComponent } from './reset-password-page/reset-password-page.component';
import {OtpComponent} from "./otp/otp.component";

export const authenticationRoutes: Route[] = [
  {
    path: RoutingNames.login,
    component: LoginComponent
  },
  {
    path: RoutingNames.loginOtp,
    component: OtpComponent
  },
  {
    path: RoutingNames.changePassword,
    component: ChangePasswordPageComponent
  },
  {
    path: RoutingNames.resetPasswordRequest,
    component: RequestPasswordResetPageComponent
  },
  {
    path: RoutingNames.passwordReset,
    component: ResetPasswordPageComponent
  },
  {
    path: RoutingNames.passwordSet,
    component: SetPasswordPageComponent
  }
];
