import { Component, Input, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'tngh-form-section',
  standalone: true,
  templateUrl: './form-section.component.html',
  styleUrls: ['./form-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  imports: [CommonModule]
})
export class FormSectionComponent {
  @Input() title = '';
  @Input() subtitle = '';
  @Input() description = '';
  @Input() info: boolean = true;
  @Input() fullWidth: boolean = false;
}
