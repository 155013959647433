<fieldset>
  <legend class="sr-only">PIN Login</legend>
  <div data-cy="pin-wrapper" class="pin-wrapper" [class.pin-wrapper--code-hidden]="isCodeHidden">
    <input
      *ngFor="let field of digits.controls; let i = index"
      #inputs
      type="tel"
      class="pin-input"
      [maxLength]="1"
      [formControl]="field"
      (paste)="onPaste($event)"
      (keydown.backspace)="backspace(i, $event)" />
  </div>
</fieldset>
