<div class="form-field">
  <label
    class="form-label"
    [class.sr-only]="labelSrOnly === true"
    *ngIf="label"
    [for]="labelId"
    >{{ label }}
    @if(required) {
      <span class="required">*</span>
    }
  </label
  >
  <span [id]="helpTextId" class="form-helptext" *ngIf="helpText">
    {{ helpText }}
  </span>
  <ng-content></ng-content>
</div>
