import {Injectable} from "@angular/core";
import {OtpConfig} from "../model/otp-config";

@Injectable()
export abstract class SharedModuleConfig {
  abstract serviceUrl?: string;
  abstract production: boolean;
  abstract keycloakUrl: string;
  abstract clientId: string;
  abstract clientSecret: string;
  abstract realm: string;
  abstract appInsights: {
    instrumentationKey: string;
  }
  otpConfig?: OtpConfig;
}
