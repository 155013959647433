import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Optional,
  QueryList,
  Self,
  ViewChildren
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormArray,
  FormBuilder,
  FormControl,
  NgControl,
  ReactiveFormsModule
} from '@angular/forms';
import { CvaBaseComponent } from 'shared';
import { untilDestroyed } from '@ngneat/until-destroy';

@Component({
  selector: 'tngh-pin-input',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule],
  templateUrl: './pin-input.component.html',
  styleUrls: ['./pin-input.component.scss']
})
export class PinInputComponent
  extends CvaBaseComponent
  implements OnInit, AfterViewInit
{
  @Input() isCodeHidden = true;
  @ViewChildren('inputs') inputs!: QueryList<ElementRef>;

  @Input()
  set numOfDigits(value: number) {
    if (value) {
      this.formControl = this.fb.array([]);

      for (let i = 0; i < value; i++) {
        this.digits.push(this.fb.control(null));
      }
    }
  }

  constructor(
    @Optional() @Self() ngControl: NgControl,
    private fb: FormBuilder
  ) {
    super(ngControl);
    this.formControl = this.fb.array([]);
    this.numOfDigits = 6;
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  override registerOnChange(fn: any): void {
    this.formControl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((form) => {
        if (form.indexOf(null) != -1) {
          this.setFocusOnElement(form.indexOf(null))
        }
        const pinCodeVal = form.join('');
        fn(pinCodeVal);
      });
  }

  override ngAfterViewInit() {
    this.setFocusOnElement();
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  override writeValue(value: any): void {
    if (value) {
      this.formControl.patchValue(value);
    } else {
      this.setFocusOnElement();
      this.formControl.reset();
    }
  }

  setFocusOnElement(index = 0) {
    this.inputs?.toArray()[index].nativeElement.focus();
  }

  get digits() {
    return this.formControl as FormArray<FormControl>;
  }

  checkIfNumbers(event: KeyboardEvent): void {
    if (isNaN(parseInt(event.key, 10)) && event.key !== 'Backspace') {
      event.preventDefault();
    }
  }
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  backspace(index: number, event: any) {
    this.checkIfNumbers(event);

    const inputArr = this.inputs.toArray();
    if (index > 0 && index <= inputArr.length - 1) {
      if (this.digits.controls[index].value) {
        this.digits.controls[index].setValue(null);
        this.setFocusOnElement(index);
      } else {
        this.digits.controls[index - 1].setValue(null);
        this.setFocusOnElement(index - 1);
      }
      event.preventDefault();
    }
  }

  onPaste($event: ClipboardEvent) {
    const characters = $event.clipboardData?.getData('text').split('') ?? [];
    characters.forEach((char, index) => this.digits.controls[index]?.setValue(char));
  }
}
