import {Injectable} from '@angular/core';
import {Observable, tap} from "rxjs";
import {SharedModuleConfig} from "../../../../config/shared-module-config";
import {OtpResponse} from "../modal/otp-response";
import {AuthService} from "./auth.service";
import {OtpLogin, User} from "../../../../model";
import {headers} from "../modal/consts";
import {Token} from "../modal/token";

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  private sessionCode: string = '';
  private userName: string = '';

  constructor(private authService: AuthService, private sharedModuleConfig:  SharedModuleConfig) {

  }

  signup(newUser: OtpLogin): Observable<OtpLogin> {
    return this.authService.http.post<OtpLogin>(`${this.sharedModuleConfig.serviceUrl}/${this.sharedModuleConfig.realm}/signup`, newUser);
  }

  sendOtpRequest(user: Partial<OtpLogin>): Observable<OtpResponse> {
    const body = this.createOtpBody(user.emailAddress!);
    if(user.password) {
      body.set('password', user.password);
    }
    this.userName = user.emailAddress!;

    return this.authService.http.post<OtpResponse>(`${this.sharedModuleConfig.keycloakUrl}/realms/${this.sharedModuleConfig.realm}/protocol/openid-connect/token`, body.toString(), {headers}).pipe(
      tap(otp => this.sessionCode = otp.session_code)
    );
  }

  loginRequest(loginUser: OtpLogin) {
    const body = this.createOtpBody(this.userName);
    body.set('session_code', this.sessionCode);
    if(loginUser.password) {
      body.set('password', loginUser.password);
    }
    body.set('code', loginUser.otp);

    return this.authService.http.post<Token>(`${this.sharedModuleConfig.keycloakUrl}/realms/${this.sharedModuleConfig.realm}/protocol/openid-connect/token`, body.toString(), {headers}).pipe(
      tap(this.authService.processToken.bind(this.authService))
    );
  }

  private createOtpBody(emailAddress: string) {
    const body = new URLSearchParams();
    body.set('client_id', this.sharedModuleConfig.clientId);
    body.set('client_secret', this.sharedModuleConfig.clientSecret);
    body.set('username', emailAddress);
    body.set('grant_type', 'password');
    return body;
  }
}
