import {Component, EventEmitter, Output} from '@angular/core';
import {AlertComponent, ButtonComponent} from "../../base";
import {FormGridComponent, FormSectionComponent} from "../../layouts";
import {FormGroup, FormsModule, NonNullableFormBuilder, ReactiveFormsModule, Validators} from "@angular/forms";
import {NgIf} from "@angular/common";
import {PinInputComponent, TextInputComponent} from "../../form-inputs";
import {OtpLogin} from "shared";

@Component({
  selector: 'lib-signup-form',
  standalone: true,
    imports: [
        AlertComponent,
        ButtonComponent,
        FormGridComponent,
        FormsModule,
        NgIf,
        PinInputComponent,
        ReactiveFormsModule,
        TextInputComponent,
        FormSectionComponent
    ],
  templateUrl: './signup-form.component.html',
  styleUrl: './signup-form.component.css'
})
export class SignupFormComponent {
  @Output() signup = new EventEmitter<OtpLogin>();

  signupForm: FormGroup;
  showFormErrors: boolean = false;

  constructor(fb: NonNullableFormBuilder) {
    this.signupForm = fb.group({
      name: ['', {validators: Validators.required}],
      surname: ['', {validators: Validators.required}],
      email: ['', {validators: [Validators.required, Validators.email]}]
    });
  }

  submit() {
    if(this.signupForm.invalid) {
      this.showFormErrors = true;
    } else {
      this.signup.emit(this.signupForm.value);
    }
  }
}
