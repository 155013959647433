import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import { AuthService, RoutingNames } from 'shared';
import { firstValueFrom, map } from 'rxjs';
import {AuthenticationConfig} from "../../../config/authentication-config";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  private authenticated = false;

  constructor(private router: Router, private authService: AuthService, private config: AuthenticationConfig) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    try {
      this.authenticated = await firstValueFrom(this.authService.isLoggedIn());
      // Force the user to log in if currently unauthenticated.
      if (!this.authenticated) {
        return this.router.createUrlTree([RoutingNames.authentication, this.config.otpConfig ? RoutingNames.loginOtp : RoutingNames.login]);
      }

      return await this.isAccessAllowed(route);
    } catch (error) {
      throw new Error('An error happened during access validation. Details:' + error);
    }
  }

  private async isAccessAllowed(route: ActivatedRouteSnapshot) {
    // Get the roles required from the route.
    const requiredRoles: string[] = route.data['roles'];

    // Allow the user to proceed if no additional roles are required to access the route.
    if (!(requiredRoles instanceof Array) || requiredRoles.length === 0) {
      return true;
    }

    // if(route.url)
    // Allow the user to proceed if at least one of the required roles are assigned to the user
    const allowed = await firstValueFrom(this.authService.getRoles().pipe(map((roles) => requiredRoles.some((role) => roles.includes(role)))));
    return allowed ? true : this.router.createUrlTree([RoutingNames.permissionDenied]);
  }
}
